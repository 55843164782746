<template>
  <div class="d-flex">
    <div class="d-flex" v-for="(link, index) in links" :key="index">
      <a v-if="index < links.length - 1" :href="link.href">{{ link.text }}</a>
      <span v-if="index == links.length < 1">
        <i class="icon fas fa-angle-right grey--text darken3"></i>
      </span>
      <span v-if="index == links.length - 1">{{ link.text }}</span>
    </div>
  </div>
</template>

<style>
a {
  text-decoration: none;
}
.fa-angle-right {
  padding: 6px 7px 0 7px;
}
</style>

<script>
export default {
  props: {
    links: Array,
  },
};
</script>
